<script>
//import DatePicker from "vue2-datepicker";
import Layout from "../../layouts/main";
// import Multiselect from "vue-multiselect";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useHttp from '@/comp-functions/useHttp'
import {formatCurrency, formatNumber} from '@/utils/formatter'
import { ref, onMounted } from '@vue/composition-api'
import Swal from "sweetalert2";
import router from "@/router"
//import i18n from "../../../i18n"

const DETAIL_ORDER = {
  oddGoodstypecode: 'GDTGNC', 
  oddGoodsTypeDescr: 'General Cargo',
  oddPackingtypecode: 'PACKRG', 
  oddPackingstypedesc: 'Karung',
  oddCollies: 3, 
  oddWeightPerColly: 15,
  oddWidth: 20, 
  oddHeight: 30, 
  oddLength: 40, 
  oddVolumePerColly: 0,
  oddChargeweightactual: 0,
  oddChargeweightround: 0,
  oddFreightcharge:0,
  oddSurchargepct:0,
  oddSurchargeamount:0,
  oddHandlingcharge:0,
  oddTotalcharge:0,
  oddRemarks: 'Remarkszz', 
  //oddChargearr:[]
  }

export default {
  page: {
    title: "Create Booking Order",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    //DatePicker,
    // Multiselect,
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Create Booking Order",
      items: [],
      select_label: "Select",
      checkedtnc : false
    };
  },
  
  setup(){
    const { $get } = useHttp()
    const { $post } = useHttp()
    const selectedSchedule = ref({})
    const configDropship = ref({})
    const orderRules = ref({})
    const form = ref({
      ordScheduleId: 1,//props.schedule_id,
      ordCollies: 0,
      ordWeightactual: 0,
      ordWeightcharge: 0,
      ordWeight: 0,
      ordFreightcharge:0,
      ordSurcharge:0,
      ordHandlingcharge:0,
      ordHandlingdgcharge:0,
      ordAdminfee:0,
      ordDiscountpct:0,
      ordDiscountamount:0,
      ordVatcharge:0,
      ordGtotalcharge:0,
      ordShipperName: '',
      ordShipperAddress: '',
      ordShipperPhone: '',
      ordConsigneeName: '',
      ordConsigneeAddress: '',
      ordConsigneePhone: '',
      details: []
    })


    const currentDetailOrder = ref({...DETAIL_ORDER})


    const getSchedule = async () => {
      const {data} = await $get({ url: 'master/schedule_available/' + form.value.ordScheduleId})
      selectedSchedule.value = data
      getVendorconfig()
      //selectedSchedule.sch_availablecapacity
    }

    const getVendorconfig = async () => {
      const {data} = await $get({ url: 'master/vendorconfigall/'+selectedSchedule.value.sch_vendor_id+'/'+selectedSchedule.value.sch_origin_port_id+'/'+selectedSchedule.value.sch_aircraft_type_code})
      orderRules.value = data.orderrule.vnc_configs.goods_validation
      configDropship.value = data.dropship.vnc_configs.dropship
    }

    const postForm = async (event) => {
      event.target.disabled = true // disable button
      const response = await $post({
        url: 'master/order',
        data: form.value
      })
      if(response.status === 'success'){
        localStorage.removeItem('order');
        router.push({name: 'apps-ordercust-created', params: {inv_id : response.data.inv_id}})
        event.target.disabled = false
      }else if(response.status === 'failed'){
        Swal.fire({
          position: "top-end",    
          icon: "error",
          title: response.messages,
          showConfirmButton: true,
          timer: 3500,
          timerProgressBar: true
        });
        event.target.disabled = false
      }
    }

    const setForm = ()=> {
      form.value = JSON.parse(localStorage.getItem('order'))
    }
  
    onMounted(() => {
     setForm()
     getSchedule()
    })

 
 return{
   selectedSchedule,
   orderRules, configDropship,
   form,
   currentDetailOrder,
    formatCurrency,
    formatNumber,  
   postForm
 }
  },
  methods: {
    customLabelConsignee ({ consignee_name, consignee_address, consignee_phone }) {
      return `${consignee_name} – ${consignee_address} – ${consignee_phone}`
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row" v-if="typeof selectedSchedule.vnd_name !== 'undefined' && selectedSchedule.sch_availablecapacity < form.ordWeight">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
              <b-alert show variant="danger">
                Sorry, the Flight Schedule you selected is no longer available
              </b-alert>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="typeof selectedSchedule.vnd_name !== 'undefined'">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-3">
                {{selectedSchedule.vnd_name}} - {{selectedSchedule.sch_flight_no}} <br/>
                Departure {{selectedSchedule.sch_departure_date}}
              </div>
              <div class="col-xl-3 text-center">
                {{selectedSchedule.sch_departure_time}} <i class="ri-arrow-right-line"></i> {{selectedSchedule.sch_arrival_time}}<br/>
                {{selectedSchedule.oriprt_locationname}} ({{selectedSchedule.oriprt_initial}}) <i class="ri-arrow-right-line"></i> {{selectedSchedule.desprt_locationname}} ({{selectedSchedule.desprt_initial}})
              </div>
              <div class="col-xl-3 text-center">
                {{Math.floor(selectedSchedule.sch_duration/60)}}h {{Math.floor(selectedSchedule.sch_duration%60)}}m<br/>
                <div v-if="selectedSchedule.sch_transit > 0">{{selectedSchedule.sch_transit}} Transit</div>
                    <div v-else>Direct</div>
              </div>
              <div class="col-xl-3 text-center">
                <h5 class="mb-0">{{ formatCurrency(selectedSchedule.sch_price_kg) }}/kg</h5>
                <div>Available <span class="font-size-20 font-weight-bold">{{ formatNumber(selectedSchedule.sch_availablecapacity) }}kg</span></div>
              </div>               
            </div>
            <br/>
            <div class="row">
              <div class="col-12">
                Notes : Goods must be in <u class="font-weight-bold">{{configDropship.locationname}}</u> a 
                maximum of <u class="font-weight-bold">{{configDropship.limithour}} hours</u> before departure time
              </div>               
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                  <div class="table-responsive">
                    <table class="table font-size-13 table-bordered">
                      <thead>
                        <tr>
                          <th>No.</th>
                          <th>Goods Type</th>
                          <th>Remarks</th>
                          <th>Collies</th>
                          <th>Total Chargeable Weight</th>
                          <th>Total Freight Charges</th>
                          <th>Total Surcharge</th>
                          <th>Total Handling Heavy Charge</th>
                          <th>Total Charge</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(detailOrder,index) in form.details" :key="index">
                          <th scope="row">{{index+1}}</th>
                          <td>{{detailOrder.oddGoodsTypeDescr}}</td>
                          <td>{{detailOrder.oddRemarks}}</td>
                          <td class="text-right">{{formatNumber(detailOrder.oddCollies)}}</td>
                          <td class="text-right">{{formatNumber(detailOrder.oddChargeweightround*detailOrder.oddCollies)}}</td>
                          <td class="text-right">{{formatNumber(detailOrder.oddFreightcharge*detailOrder.oddCollies)}}</td>
                          <td class="text-right">{{formatNumber(detailOrder.oddSurchargeamount*detailOrder.oddCollies)}}</td>
                          <td class="text-right">{{formatNumber(detailOrder.oddHandlingcharge*detailOrder.oddCollies)}}</td>
                          <td class="text-right">{{formatNumber(detailOrder.oddTotalcharge)}}</td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr class="font-weight-bold">
                          <td class="text-right" colspan="3">Total</td>
                          <td class="text-right">{{formatNumber(form.ordCollies)}}</td>
                          <td class="text-right">{{formatNumber(form.ordWeight)}}</td>
                          <td class="text-right">{{formatNumber(form.ordFreightcharge)}}</td>
                          <td class="text-right">{{formatNumber(form.ordSurcharge)}}</td>
                          <td class="text-right">{{formatNumber(form.ordHandlingcharge)}}</td>
                          <td class="text-right">{{formatNumber(form.ordFreightcharge+form.ordSurcharge+form.ordHandlingcharge)}}</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                  <div class="row">
                    <div class="offset-md-7 col-md-5">
                      <b-form-group
                        label-cols-sm="5"
                        label-cols-lg="5"
                        class="mb-0"
                        label="Handling DG Charge"
                        label-for=""
                        v-if="form.ordHandlingdgcharge > 0"
                      >
                        <div class="pt-2 text-right"><h6>{{ formatNumber(form.ordHandlingdgcharge) }}</h6></div>
                      </b-form-group>
                      <hr class="m-0"/>
                      <b-form-group
                        label-cols-sm="5"
                        label-cols-lg="5"
                        class="mb-0"
                        label="Admin SMU"
                        label-for=""
                      >
                        <div class="pt-2 text-right"><h6>{{ formatNumber(form.ordAdminfee) }}</h6></div>
                      </b-form-group>
                      <hr class="m-0"/>
                      <b-form-group
                        label-cols-sm="5"
                        label-cols-lg="5"
                        class="mb-0"
                        label="Admin Fee"
                        label-for=""
                      >
                        <div class="pt-2 text-right"><h6>{{ formatNumber(form.ordVatcharge) }}</h6></div>
                      </b-form-group>
                      <hr class="m-0"/>
                      <b-form-group
                        label-cols-sm="5"
                        label-cols-lg="5"
                        class="mb-0"
                        label="Grand Total"
                        label-for=""
                      >
                        <div class="pt-2 text-right"><h6>{{ formatNumber(form.ordGtotalcharge) }}</h6></div>
                      </b-form-group>
                      <hr class="m-0"/>
                    </div>
                  </div>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 text-center" v-if="selectedSchedule.sch_availablecapacity >= form.ordWeight">
                    <h5 class="font-size-14">Payment method :</h5>
                    <div class="row">
                      <div class="col-lg-4 col-sm-6">
                        <div>
                          <label class="card-radio-label mb-3">
                            <input
                              type="radio"
                              name="pay-method"
                              id="pay-methodoption1"
                              class="card-radio-input"
                              checked
                            />

                            <div class="card-radio">
                              <span>Bank Transfer</span>
                            </div>
                          </label>
                        </div>
                      </div>

                      <div class="col-lg-4 col-sm-6">
                        <div>
                          <label class="card-radio-label mb-0">
                            <input
                              type="radio"
                              name="pay-method"
                              id="pay-methodoption2"
                              class="card-radio-input"
                              disabled
                            />
                            <div class="card-radio">
                              <span>Transfer Virtual Account</span>
                            </div>
                          </label>
                          <i>*Comming Soon</i>
                        </div>
                      </div>

                      <div class="col-lg-4 col-sm-6">
                        <div>
                          <label class="card-radio-label mb-0">
                            <input
                              type="radio"
                              name="pay-method"
                              id="pay-methodoption3"
                              class="card-radio-input"
                              disabled
                            />
                            <div class="card-radio">
                              <span>Pay Later - Comming Soon</span>
                            </div>
                          </label>
                          <i>*Comming Soon</i>
                        </div>
                      </div>
                    </div>
                <hr/>
                <div class="form-group">
                  <div class="custom-control custom-checkbox">
                    <input id="invalidCheck" type="checkbox" class="custom-control-input" v-model="checkedtnc" />
                    <label
                      class="custom-control-label"
                      for="invalidCheck"
                    >Agree to terms and conditions</label>
                    <div class="invalid-feedback">You must agree before submitting.</div>
                  </div>
                  <a v-b-modal.modal-lg href="#">
                    Show terms and conditions
                  </a>
                </div>
                <div class="button-items">
                  <b-button variant="primary" @click="postForm" :disabled="!checkedtnc">
                    <i class="ri-save-line align-middle"></i>
                    Submit
                  </b-button>
                  <b-button variant="danger" :to="{ name: 'apps-order-createinput'}">
                    <i class="ri-arrow-left-line align-middle"></i> Back
                  </b-button>
                </div>
              </div>
              <div class="col-12 text-center" v-else>
                <div class="button-items">
                  <b-button variant="danger" :to="{ name: 'apps-order-createinput'}">
                    <i class="ri-arrow-left-line align-middle"></i> Back
                  </b-button>
                </div>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
    <b-modal
      id="modal-lg"
      size="xl"
      title="Term &amp; Conditions"
      title-class="font-20"
      hide-footer
    >
      <ol class="font-size-18">
        <li>
          Adalah benar yang di isi baik jumlah collies, jumlah berat dan kemasan serta jenis barang
        </li>
        <li>
          Pengisian kolom adalah dinyatakan sebagai PTI (Pemberitahuan Tentang Isi)
        </li>
        <li>
          Apabila ditemukan beda jumlah berat maka bersedia membayar kekurangan atas jumlah berat tersebut
        </li>
        <li>
          Perhitungan jumlah berat terakhir yang dipakai adalah jumlah berat hasil timbangan di Gudang Bandara asal, 
          apabila ada selisih maka akan dihubungi pihak customer service untuk diberikan BTB (bukti timbang barang) 
          sebagai bukti kekurangan pembayaran.
        </li>
        <li>
          Apabila diketemukan barang DANGEROUS GOODS dan ditahan oleh pihak avsec bandara 
          maka bersedia untuk diproses dan sepenuhnya bukan merupakan tanggung jawab kami.
        </li>
        <li>
          Apabila barang tiba dibandara melebihi dari batas maksimum yang sudah diberikan dan mengakibatkan 
          tidak dapat terbang sesuai dengan bookingan atas flight yang dipilih maka sepenuhnya bukan merupakan 
          tanggung jawab kami dan dinyatakan hangus atas pembayaran yang sudah diterima.
        </li>
        <li>
          Pembayaran yang sudah melebihi batas waktu yang ditentukan maka bookingan tersebut akan dinyatakan 
          hangus dan harus melakukan booking ulang.
        </li>
        <li>
          Apabila terjadi delay dan offload yang diakibatkan technical operation maka akan dinaikkan ke next flight (Double fuel, Return to base, embargo, trouble engine, bad weather)
        </li>
      </ol>
    </b-modal>
  </Layout>
</template>

